var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c("Header", {
        attrs: {
          title: "Natureza das rubricas",
          subtitle: "Gerenciamento das atualizações dos leiautes do e-Social",
          itemsBreadCrumb: _vm.itemsBreadCrumb,
          hasUpdateInfo: false,
          hasAddButton: true,
          addButtonText: "Adicionar natureza",
        },
        on: { clickAdd: _vm.openModal },
      }),
      _c(
        "div",
        { attrs: { id: "tables-container" } },
        [
          _c(
            "v-card",
            { staticClass: "pl-0 pr-0 pt-0 pb-0" },
            [
              _c(
                "v-card-title",
                [
                  _c("TableSearch", {
                    attrs: {
                      label: "Pesquisar por código, nome ou descrição",
                      id: "rubricSearchBar",
                    },
                    on: { input: _vm.onInputSearchBar },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  }),
                  _c("v-spacer"),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "text-right",
                          attrs: { cols: "12", sm: "12" },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "max-width": "384px !important",
                                "min-width": "384px !important",
                                "max-height": "544px !important",
                                "min-height": "544px !important",
                              },
                              attrs: { id: "attachId" },
                            },
                            [
                              _c(
                                "v-menu",
                                {
                                  staticStyle: {
                                    "max-width": "384px !important",
                                    "min-width": "384px !important",
                                    "max-height": "544px !important",
                                    "min-height": "544px !important",
                                  },
                                  attrs: {
                                    "offset-y": "",
                                    left: "",
                                    "close-on-content-click": false,
                                    "close-on-click": _vm.closeOnClick,
                                    attach: "#attachId",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass:
                                                    "btn-ag-secondary",
                                                  attrs: { icon: "" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.clickButtonFilter()
                                                    },
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass:
                                                    "material-icons-outlined",
                                                },
                                                [_vm._v("filter_alt")]
                                              ),
                                              _vm._v(
                                                " Filtro " +
                                                  _vm._s(
                                                    _vm.totalSelectedFilters >
                                                      0 && !_vm.openMenu
                                                      ? `(${_vm.totalSelectedFilters})`
                                                      : ""
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.openMenu,
                                    callback: function ($$v) {
                                      _vm.openMenu = $$v
                                    },
                                    expression: "openMenu",
                                  },
                                },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "pa-0",
                                      staticStyle: {
                                        "max-width": "384px !important",
                                        "min-width": "384px !important",
                                        "max-height": "545px !important",
                                        "min-height": "545px !important",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass: "pa-4",
                                          staticStyle: {
                                            "max-height": "64px !important",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "text-h5",
                                              style:
                                                "color: #182026;font-weight:700;line-height:30px!important;font-size:20px!important;height:30px!important",
                                            },
                                            [_vm._v("Filtro")]
                                          ),
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "pa-0 ma-0",
                                              attrs: { icon: "", dark: "" },
                                              on: { click: _vm.closeFromX },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { staticClass: "mr-0" },
                                                [_vm._v("mdi-close")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list",
                                        {
                                          staticClass:
                                            "ma-0 pa-0 rubric_nature_filters",
                                          staticStyle: {
                                            "max-height": "480px !important",
                                            "min-height": "480px !important",
                                            "border-top":
                                              "1px solid var(--v-secondary-lighten5) !important",
                                          },
                                          attrs: { width: "384" },
                                        },
                                        [
                                          _c(
                                            "v-list-item",
                                            { staticClass: "pb-0 mb-4" },
                                            [
                                              _c("v-combobox", {
                                                staticClass:
                                                  "combobox-rubric-nature-filter",
                                                attrs: {
                                                  items: _vm.situations,
                                                  label: "Situação",
                                                  "hide-details": "",
                                                  outlined: "",
                                                  dense: "",
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "item",
                                                    fn: function (data) {
                                                      return [
                                                        _c(
                                                          "v-list-item-content",
                                                          {
                                                            staticClass:
                                                              "combobox-rubric-nature-filter-item-list",
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        data.item
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ]),
                                                model: {
                                                  value: _vm.filter.situation,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.filter,
                                                      "situation",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "filter.situation",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item",
                                            { staticClass: "pb-0 mb-4" },
                                            [
                                              _c("AGDatePickerRangeMonth", {
                                                staticStyle: {
                                                  "min-width":
                                                    "352px !important",
                                                },
                                                attrs: {
                                                  id: "datepickerPeriodStart",
                                                  label: "Período de início",
                                                  requireTwoDates: true,
                                                },
                                                model: {
                                                  value: _vm.filter.start,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.filter,
                                                      "start",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "filter.start",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item",
                                            { staticClass: "pb-0 mb-4" },
                                            [
                                              _c("AGDatePickerRangeMonth", {
                                                staticStyle: {
                                                  "min-width":
                                                    "352px !important",
                                                },
                                                attrs: {
                                                  id: "datepickerPeriodEnd",
                                                  label: "Período de término",
                                                  requireTwoDates: true,
                                                },
                                                model: {
                                                  value: _vm.filter.end,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.filter,
                                                      "end",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "filter.end",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item",
                                            { staticClass: "pb-0 mb-4" },
                                            [
                                              _c("v-combobox", {
                                                staticClass:
                                                  "combobox-rubric-nature-filter",
                                                attrs: {
                                                  items: _vm.usersComboboxItems,
                                                  label: "Atualizado por",
                                                  "hide-details": "",
                                                  outlined: "",
                                                  dense: "",
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "item",
                                                    fn: function ({ item }) {
                                                      return [
                                                        _c(
                                                          "v-list-item-content",
                                                          {
                                                            staticClass:
                                                              "combobox-rubric-nature-filter-item-list",
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ]),
                                                model: {
                                                  value: _vm.filter.updateBy,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.filter,
                                                      "updateBy",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "filter.updateBy",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item",
                                            { staticClass: "pa-4" },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  style:
                                                    _vm.habilitarBotaoFiltro
                                                      ? "width: 352px; max-height: 48px !important; min-height: 48px !important; background-color: #e7e7fa !important;color:#8686A2!important"
                                                      : "width: 352px; max-height: 48px !important; min-height: 48px !important; background-color: var(--v-primary-base) !important;color:white!important",
                                                  attrs: {
                                                    disabled:
                                                      _vm.habilitarBotaoFiltro,
                                                  },
                                                  on: { click: _vm.callFilter },
                                                },
                                                [_vm._v(" Aplicar ")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item",
                                            { staticClass: "pb-4" },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  class:
                                                    _vm.habilitarBotaoLimparFiltro
                                                      ? "clear-filter-disabled"
                                                      : "clear-filter-enabled",
                                                  attrs: {
                                                    disabled:
                                                      _vm.habilitarBotaoLimparFiltro,
                                                  },
                                                  on: {
                                                    click: _vm.limparFiltro,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "material-symbols-outlined",
                                                      staticStyle: {
                                                        "max-width": "1px",
                                                        position: "fixed",
                                                        "margin-left": "-150px",
                                                      },
                                                    },
                                                    [_vm._v(" delete ")]
                                                  ),
                                                  _vm._v(" Limpar filtro"),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "btn-ag-tertiary ml-2",
                                staticStyle: { width: "127px" },
                                attrs: { icon: "", id: "exportBtn" },
                                on: {
                                  click: function ($event) {
                                    return _vm.exportar()
                                  },
                                },
                              },
                              [
                                _c("v-icon", [_vm._v("mdi-download-outline")]),
                                _vm._v(" Exportar "),
                              ],
                              1
                            ),
                          ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12" } },
                    [
                      [
                        _c(
                          "div",
                          { staticClass: "text-right" },
                          [
                            _vm.showChipSituation && !_vm.openMenu
                              ? _c(
                                  "v-chip",
                                  {
                                    staticClass: "ma-1",
                                    attrs: { close: "" },
                                    on: {
                                      "click:close": function ($event) {
                                        return _vm.removeSituation()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.capitalize(_vm.filter.situation)
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.showChipPeriodStart && !_vm.openMenu
                              ? _c(
                                  "v-chip",
                                  {
                                    staticClass: "ma-1",
                                    attrs: { close: "" },
                                    on: {
                                      "click:close": function ($event) {
                                        return _vm.removeChipPeriodStart()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.concatDate(
                                            _vm.filter.start,
                                            "Início: "
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.showChipPeriodEnd && !_vm.openMenu
                              ? _c(
                                  "v-chip",
                                  {
                                    staticClass: "ma-1",
                                    attrs: { close: "" },
                                    on: {
                                      "click:close": function ($event) {
                                        return _vm.removeChipPeriodEnd()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.concatDate(
                                            _vm.filter.end,
                                            "Témino: "
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.showChipUpdateBy && !_vm.openMenu
                              ? _c(
                                  "v-chip",
                                  {
                                    staticClass: "ma-1",
                                    attrs: { close: "" },
                                    on: {
                                      "click:close": function ($event) {
                                        return _vm.removeUpdateBy()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.capitalize(
                                            `${_vm.filter.updateBy}`
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "table_scroll_duplicate",
                  attrs: { id: "scrollDuplicate" },
                },
                [_c("div", { attrs: { id: "dummy" } })]
              ),
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.natures,
                  "no-data-text": _vm.noDataText,
                  loading: _vm.loading,
                  options: _vm.pagination,
                  page: _vm.pagination.page,
                  "server-items-length": _vm.totalNatures,
                  "footer-props": {
                    "items-per-page-options": [50, 100, 250],
                    itemsPerPageText: `Natureza por página: `,
                    disableItemsPerPage: _vm.natures.length < 50,
                  },
                },
                on: {
                  "update:options": function ($event) {
                    _vm.pagination = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "footer.page-text",
                    fn: function (items) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(items.pageStart) +
                            " - " +
                            _vm._s(items.pageStop) +
                            " de " +
                            _vm._s(_vm.totalNatures) +
                            " naturezas "
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.name",
                    fn: function ({ item }) {
                      return [
                        _vm.checkLength(item.name)
                          ? _c(
                              "AGTooltip",
                              { attrs: { tooltipText: item.name } },
                              [
                                [
                                  _c("span", { staticClass: "text-no-wrap" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.truncateText(item.name)) +
                                        " "
                                    ),
                                  ]),
                                ],
                              ],
                              2
                            )
                          : _c("div", [
                              _c("span", { staticClass: "text-no-wrap" }, [
                                _vm._v(" " + _vm._s(item.name) + " "),
                              ]),
                            ]),
                      ]
                    },
                  },
                  {
                    key: "item.desc",
                    fn: function ({ item }) {
                      return [
                        _vm.checkLength(item.desc)
                          ? _c(
                              "AGTooltip",
                              { attrs: { tooltipText: item.desc } },
                              [
                                [
                                  _c("span", { staticClass: "text-no-wrap" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.truncateText(item.desc)) +
                                        " "
                                    ),
                                  ]),
                                ],
                              ],
                              2
                            )
                          : _c("span", [_vm._v(" " + _vm._s(item.desc) + " ")]),
                      ]
                    },
                  },
                  {
                    key: "item.user",
                    fn: function ({ item }) {
                      return [
                        _vm.checkLength(item.user)
                          ? _c(
                              "AGTooltip",
                              { attrs: { tooltipText: item.user } },
                              [
                                [
                                  _c("span", { staticClass: "text-no-wrap" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.truncateText(item.user)) +
                                        " "
                                    ),
                                  ]),
                                ],
                              ],
                              2
                            )
                          : _c("span", [
                              _c("span", { staticClass: "text-no-wrap" }, [
                                _vm._v(" " + _vm._s(item.user) + " "),
                              ]),
                            ]),
                      ]
                    },
                  },
                  {
                    key: "item.actions",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: { icon: "", id: "idEdit" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openModalEdit(item)
                                                },
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-0",
                                              attrs: {
                                                small: "",
                                                color: "success",
                                              },
                                            },
                                            [_vm._v(" mdi-pencil-outline ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [_c("span", [_vm._v("Editar")])]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                icon: "",
                                                id: "idHistoric",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openModalHistoric(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-0",
                                              attrs: { small: "" },
                                            },
                                            [_vm._v(" mdi-history")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [_c("span", [_vm._v("Histórico")])]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("ModalRubricNatureNew", {
        ref: "modalRubricNatureNew",
        attrs: { natures: _vm.natures },
        on: { refresh: _vm.refreshFetch },
      }),
      _c("ModalRubricNatureEdit", {
        ref: "modalRubricNatureEdit",
        attrs: { nature: _vm.nature },
        on: { refresh: _vm.refreshFetch },
      }),
      _c("ModalRubricNatureHistoric", {
        ref: "modalRubricNatureHistoric",
        attrs: { history: _vm.arrHistoric },
      }),
      _c("ModalRubricNatureHistoricUpdate", {
        ref: "modalRubricNatureHistoricUpdate",
        attrs: { history: _vm.arrHistoric },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }